::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    background: var(--blue);
    border-radius: 5px;
}
::-webkit-scrollbar-track {
    background: var(--bg-alt-color);
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: 300ms ease-in-out;
	-moz-transition: 300ms ease-in-out;
	transition: 300ms ease-in-out;
}

:root {
	--bg-color: #181818;
	--bg-alt-color: #232323;
	--text-color: white;
	--blue: #007bff;
}

.light {
	--bg-color: #fafafa;
	--bg-alt-color: rgb(244, 254, 255);
	--text-color: black;
}

a {
	text-decoration: none !important;
}
