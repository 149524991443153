/*
	GLOBAL CLASSES
*/
body {
	color: var(--text-color);
	background-color: var(--bg-color);
}

button {
	border-radius: 10px !important;
}

label {
	font-weight: 600;
}

/*
	HEADER COMPONENT
*/
.HeaderComponent {
	padding-top: 20px;
	position: relative;
	z-index: 12;
	background-color: var(--bg-color);
}

.headerElements {
	padding: 15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.themeBtn {
	margin-left: 50px;
	cursor: pointer;
}

.themeText {
	cursor: pointer;
}

/*
	FILTER COMPONENT
*/
.FilterComponent {
	padding: 10px;
	border-radius: 0 0 25px 25px;
	background-color: var(--bg-alt-color);
}

.titlesContainer,
.filters,
.search {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
}

.toggleFilters {
	display: none;
}

.search .searchByTitle {
	width: 400px;
}

.search .searchById {
	width: 200px;
}

/*
	TITLES COMPONENT
*/
.titleInfo {
	margin: 10px;
	position: relative;
	width: fit-content;
	padding: 10px 20px;
	background-color: var(--bg-alt-color);
	border-radius: 8%;
	transition: transform 250ms ease-in-out;
	z-index: 10;
}

.titleInfo:hover {
	transform: scale(1.02);
}

.titleInfo img {
	width: 200px;
	border-radius: 20px;
}

.resultsNum {
	position: sticky;
	top: 25px;
	padding: 15px;
	margin: 15px;
	width: fit-content;
	display: inline-block;
	color: white;
	background-color: var(--blue);
	border-radius: 20px;
	z-index: 11;
}

/*
	TITLE INFO COMPONENT
*/
.TitleInfoComponent {
	min-height: calc(100vh - 250px);
}

.titleData,
.otherTitles {
	position: relative;
	margin-top: 35px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	color: white;
	z-index: 11;
}

.otherTitlesHeading {
	margin-top: 40px;
}

.titlePoster {
	width: 250px;
}

.otherTitlePoster {
	width: 150px;
	margin: 0 20px;
}

.titlePoster img,
.otherTitlePoster img {
	width: 100%;
	border-radius: 20px;
	transition: transform 250ms ease-in-out;
}

.titlePoster img:hover,
.otherTitlePoster img:hover {
	transform: scale(1.04);
}

.titleContent {
	margin-left: 40px;
	text-align: left;
	width: 50%;
}

.backdrop {
	position: fixed;
	top: 0;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	filter: brightness(25%);
	z-index: 1;
}

/*
	FOOTER COMPONENT
*/
.backToTop {
	position: fixed;
	bottom: 25px;
	right: 25px;
	z-index: 15;
	cursor: pointer;
	background-color: var(--bg-color);
	border-radius: 50%;
	transition: transform 250ms ease-in-out;
}

.backToTop:hover {
	transform: scale(1.1);
}

.FooterComponent {
	position: relative;
	padding: 15px;
	z-index: 12;
	background-color: var(--bg-color);
}

/*
	RESPONSIVE CLASSES
*/
@media screen and (max-width: 768px) {
	.titleInfo {
		width: 150px;
		padding: 10px;
		font-size: 0.85rem;
	}

	.titleInfo img {
		width: 130px;
	}

	.search .searchById {
		display: none;
	}

	.toggleFilters {
		display: block;
	}

	.titleData {
		margin: 50px;
	}

	.titlePoster {
		width: 175px;
	}

	.otherTitlePoster {
		width: 140px;
		margin: 0 15px;
	}

	.titleContent {
		margin-left: unset;
		width: unset;
	}
}
